/* styles.css (または任意のCSSファイル) */

.modal-dialog {
    /* ダイアログのスタイル */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* 任意の値。他の要素よりも大きな値に設定する */
  }
  
  .modal-overlay {
    /* オーバーレイのスタイル */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999; /* ダイアログよりも小さな値に設定する */
  }
  
  
  .modal-dialog h2 {
    margin-bottom: 10px;
  }
  
  .modal-button-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .modal-confirm-button,
  .modal-cancel-button {
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-confirm-button {
    background-color: #4caf50;
    color: #fff;
  }
  
  .modal-cancel-button {
    background-color: #f44336;
    color: #fff;
  }
  